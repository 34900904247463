import { Controller } from '@hotwired/stimulus'
import { Wagmi } from '@/web3/config/wagmi.js'
import { getGasPrice } from '@wagmi/core'
import { getNetworkFee } from '@/web3/utils/networkFee'

import { getAddress, parseEther, formatEther, formatGwei } from 'viem'
import { getNativePrice } from '../../web3/services/tokenPriceService'

export default class extends Controller {
  static targets = ['usd', 'native', 'total']

  async connect() {
    document.addEventListener('chainUpdated', (event) => {
      this.chain = event.detail.chain
    })
    document.addEventListener('showNetworkFee', this.showNetworkFee.bind(this))
  }

  disconnect() {
    document.removeEventListener('showNetworkFee', this.showNetworkFee.bind(this))
  }

  async showNetworkFee() {
    const chainId = Wagmi.config.state.chainId
    const total = Number(this.element.dataset.total)
    const priceRep = await getNativePrice(this.chain)
    const { feeNative, feeRep } = await getNetworkFee(this.chain, this.chain.gas)

    this.nativeTarget.innerHTML = `~${feeNative.toFixed(5)} ${this.chain.currency}`
    this.usdTarget.innerHTML = ` ~$${feeRep.toFixed(3)} USD`
    this.totalTarget.innerHTML = ` ~$${(total + feeNative * priceRep).toFixed(2)} USD`
  }
}
