import { getGasPrice } from '@wagmi/core'
import { formatEther, formatGwei } from 'viem'
import { getNativePrice } from '../../web3/services/tokenPriceService'
import { Wagmi } from '@/web3/config/wagmi.js'

export async function getNetworkFee(chain, gasUsed) {
  const priceRep = await getNativePrice(chain)
  const gasPrice = await getGasPrice(Wagmi.config)
  const fee = gasPrice * BigInt(gasUsed)
  const feeNative = Number(formatEther(fee))
  const feeRep = Number(feeNative * priceRep)
  return { feeNative, feeRep }
}
