export default function dateNow() {
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  }
  return new Date().toLocaleString('en-US', options).replace(',', '')
}
