import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'loader',
    'checkoutHeader',
    'chainSelect',
    'connectors',
    'walletInfo',
    'checkout',
    'pay',
    'walletInfoButton',
    'hideOnSelectToken',
    'showOnSelectToken',
    'paymentSuccess',
  ]

  connect() {
    this.showLoader()
    this.defaultView()
  }

  defaultView() {
    this.showTargets('checkoutHeader', 'checkout', 'connectors', 'chainSelect')
    this.hideTargets('walletInfo', 'pay', 'walletInfoButton')
  }

  onConnectView() {
    this.showTargets('checkoutHeader', 'checkout', 'pay', 'walletInfoButton', 'hideOnSelectToken')
    this.hideTargets('walletInfo', 'connectors', 'showOnSelectToken')
  }

  showLoader() {
    this.showTargets('loader')
  }

  hideLoader() {
    this.hideTargets('loader')
  }

  showTokens() {
    this.showTargets('showOnSelectToken')
    this.hideTargets('checkoutHeader', 'hideOnSelectToken')
  }

  hideTokens() {
    this.showTargets('checkoutHeader', 'hideOnSelectToken')
    this.hideTargets('showOnSelectToken')
  }

  showWalletInfo() {
    this.showTargets('walletInfo')
    this.hideTargets('chainSelect', 'checkoutHeader', 'pay')
  }

  hideWalletInfo() {
    this.hideTargets('walletInfo')
    this.showTargets('chainSelect', 'checkoutHeader', 'pay')
  }

  showTargets(...targetNames) {
    targetNames.forEach((targetName) => {
      this[`${targetName}Target`].classList.remove('hidden')
    })
  }

  hideTargets(...targetNames) {
    targetNames.forEach((targetName) => {
      this[`${targetName}Target`].classList.add('hidden')
    })
  }
}
