import { quoteExactOutput } from '@/web3/logic/uniswapQuote.js'
import { getTokenAllowance } from '@/web3/logic/getTokenAllowance.js'
import { approve } from '@/web3/logic/approve.js'
import { Checkout } from '@/web3/logic/checkout.js'
import { Charge } from '@/web3/logic/charge.js'
import { getSignature } from '@/web3/utils/permit.js'
import { fromReadableAmount, bigIntify } from '@/web3/utils/conversion.js'
import { ONE_PERCENT, percent_mul } from '@/web3/utils/percentage.js'
import { isAddressEqual, getAddress } from 'viem'
import { FeeAmount } from '@uniswap/v3-sdk'
import { Token } from '@uniswap/sdk-core'
import { post, put } from '@rails/request.js'

function dispatchTransactionEvent(state, detail) {
  const event = new CustomEvent('transactionServiceEvent', {
    detail: { state, ...detail },
  })
  document.dispatchEvent(event)
}

export async function processTransaction(tokenSelected, chain, amountValue) {
  let inputAmount
  let maxWillingToPay
  let hash
  let charge
  let checkout
  let chargeId
  let signature

  const recipientCurrency = new Token(
    chain.id,
    getAddress(chain.recipient_currency.address),
    chain.recipient_currency.decimals,
    chain.recipient_currency.symbol,
    chain.recipient_currency.name
  )

  if (tokenSelected.symbol != chain.recipient_currency.symbol) {
    inputAmount = (
      await quoteExactOutput(
        fromReadableAmount(amountValue, recipientCurrency.decimals),
        tokenSelected,
        recipientCurrency,
        FeeAmount.MEDIUM
      )
    ).result

    // Add slippage
    maxWillingToPay = percent_mul(inputAmount, ONE_PERCENT) + bigIntify(inputAmount)
  }

  charge = await Charge.getData()
  chargeId = charge.web3_data.transfer_intent.metadata.public_id
  checkout = new Checkout(charge.web3_data.transfer_intent)

  if (tokenSelected.symbol === chain.currency) {
    hash = await checkout.swapAndTransferNative(maxWillingToPay)
  } else if (isAddressEqual(tokenSelected.address, chain.recipient_currency.address)) {
    dispatchTransactionEvent('approve', { token: tokenSelected, maxWillingToPay })
    signature = await getSignature(chain, tokenSelected, charge.web3_data.transfer_intent.call_data)
    dispatchTransactionEvent('approved', { token: tokenSelected, maxWillingToPay })
    hash = await Charge.payGasLess(signature)
  } else {
    const allowance = await getTokenAllowance(
      tokenSelected.address,
      chain.contract_address,
      Charge.current.address
    )
    if (allowance < maxWillingToPay) {
      dispatchTransactionEvent('approve', { token: tokenSelected, maxWillingToPay })
      await approve(tokenSelected.address, chain.contract_address, maxWillingToPay)
      dispatchTransactionEvent('approved', { token: tokenSelected, maxWillingToPay })
    }
    hash = await checkout.swapAndTransferToken(tokenSelected.address, maxWillingToPay)
  }
  return { hash, chargeId }
}
