import getChainLogoPath from '@/controllers/helpers/getChainLogoPath'

export class RenderChain {
  static currentChainImage(chain) {
    return `
    <img src="${getChainLogoPath(chain.name)}" alt="${chain.name}" >
    `
  }
  static buttonHtml(chain, currentChainId) {
    const activeClasses = chain.id === currentChainId ? 'bg-white bg-opacity-5' : ''
    return `
      <button data-action="click->web3--switch-chain#switchChain" data-action="click->dropdown#toggle" data-chain-id="${chain.id}" class="text-white h-[36px] px-2 py-1 inline-flex items-center gap-3 text-sm rounded hover:bg-white hover:bg-opacity-5 ${activeClasses}" role="menuitem">
          <img src="${getChainLogoPath(chain.name)}" alt="${chain.name}" >
          ${chain.name}
      </button>
    `
  }
  static logoWithTitle(chain) {
    return `
      <span class="inline-flex items-center gap-2">
        <img src="${getChainLogoPath(chain.name)}" alt="${chain.name}" class="h-5 w-5" >
        ${chain.name}
      </span>
    `
  }
}
