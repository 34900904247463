import { FeeAmount } from '@uniswap/v3-sdk'
import { prepareTransactionRequest, writeContract } from '@wagmi/core'
import { IPAYMENTS_ABI } from '@/web3/abis/IPAYMENTS_ABI.js'
import { Wagmi } from '@/web3/config/wagmi.js'
import { parseGwei, createWalletClient, http, publicActions } from 'viem'
import { privateKeyToAccount } from 'viem/accounts'
import { mainnet, polygon } from '@wagmi/core/chains'

export class Checkout {
  constructor(transfer_intent) {
    this.contract_address = transfer_intent.metadata.contract_address
    this.intent = Checkout.generateIntent(transfer_intent.call_data)
  }

  async swapAndTransferNative(amount) {
    const request = await prepareTransactionRequest(Wagmi.config, {
      address: this.contract_address,
      abi: IPAYMENTS_ABI,
      functionName: 'swapAndTransferUniswapV3Native',
      args: [this.intent, FeeAmount.MEDIUM],
      value: amount,
      gas: 5000000,
    })
    const hash = await writeContract(Wagmi.config, { ...request, __mode: 'prepared' })
    return hash
  }

  async swapAndTransferToken(tokenIn, maxWillingToPay) {
    const request = await prepareTransactionRequest(Wagmi.config, {
      address: this.contract_address,
      abi: IPAYMENTS_ABI,
      functionName: 'swapAndTransferUniswapV3TokenPreApproved',
      args: [this.intent, tokenIn, maxWillingToPay, FeeAmount.MEDIUM],
      maxPriorityFeePerGas: 1000000000n,
      gas: 5000000,
    })

    const hash = await writeContract(Wagmi.config, { ...request, __mode: 'prepared' })
    return hash
  }

  async transferTokenPreApproved() {
    const request = await prepareTransactionRequest(Wagmi.config, {
      address: this.contract_address,
      abi: IPAYMENTS_ABI,
      functionName: 'transferTokenPreApproved',
      args: [this.intent],
      gas: 5000000,
      maxFeePerGas: parseGwei('20'),
      maxPriorityFeePerGas: parseGwei('2'),
    })
    const hash = await writeContract(Wagmi.config, request)
    return hash
  }

  async transferToken(signature) {
    const request = await prepareTransactionRequest(Wagmi.config, {
      address: this.contract_address,
      abi: IPAYMENTS_ABI,
      functionName: 'transferToken',
      args: [this.intent, signature],
      gas: 5000000,
      maxFeePerGas: parseGwei('20'),
      maxPriorityFeePerGas: parseGwei('2'),
    })
    const hash = await writeContract(Wagmi.config, request)

    return hash
  }

  async transferNative() {
    const request = await prepareTransactionRequest(Wagmi.config, {
      address: this.contract_address,
      abi: IPAYMENTS_ABI,
      functionName: 'transferNative',
      args: [this.intent],
      value: intent.amount,
      gas: 150000,
    })
    const hash = await writeContract(Wagmi.config, { ...request, __mode: 'prepared' })
    return hash
  }

  static generateIntent(charge) {
    return {
      recipientAmount: charge.recipient_amount,
      deadline: charge.deadline,
      recipient: charge.recipient,
      recipientCurrency: charge.recipient_currency,
      refundDestination: charge.refund_destination,
      feeAmount: charge.fee_amount,
      id: charge.id,
      operator: charge.operator,
      signature: charge.signature,
      prefix: charge.prefix,
    }
  }
}
