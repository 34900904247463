import { Controller } from '@hotwired/stimulus'
import { getTokenPriceRep } from '@/web3/services/tokenPriceService.js'
import { Wagmi } from '@/web3/config/wagmi'
import { Token } from '@uniswap/sdk-core'
import { getAddress } from 'viem'
import { Charge } from '@/web3/logic/charge.js'

export default class extends Controller {
  static targets = ['price', 'token']
  static values = {
    token: Object,
  }

  async connect() {
    this.chain = Charge.current.chain
    this.initializeTokenVisibility()
    this.scheduleTokenBalanceUpdates()

    document.addEventListener('chainUpdated', (event) => {
      this.chain = event.detail.chain
    })
  }

  disconnect() {
    clearInterval(this.updateInterval)
  }

  initializeTokenVisibility() {
    // add hidden if token isn't native on load
    if (this.tokenValue.native) {
      this.element.classList.remove('hidden')
      this.dispatchEvent(
        'tokenSelected',
        new Token(
          this.tokenValue.chain_id,
          getAddress(this.tokenValue.address),
          this.tokenValue.decimals,
          this.tokenValue.symbol,
          this.tokenValue.name
        )
      )
    }
  }

  // updating token balance
  scheduleTokenBalanceUpdates() {
    this.updateTokenBalanceDisplay()
    this.updateInterval = setInterval(() => {
      this.updateTokenBalanceDisplay()
    }, 100000) // each 10s
  }

  async updateTokenBalanceDisplay() {
    const token = this.tokenValue
    const chainId = Wagmi.config.state.chainId
    try {
      const usdcPrice = await getTokenPriceRep(token, this.chain)
      const balanceInUsdc = (token.token_balance * usdcPrice).toFixed(2)
      // Check balance and update or hide token display
      if (balanceInUsdc > 0) {
        this.priceTarget.innerHTML = `$${balanceInUsdc}`
        this.priceTarget.classList.remove('hide-element')
      } else {
        this.priceTarget.classList.add('hide-element')
      }
    } catch (error) {
      console.log(`Error ${error}:`)
    }
  }

  // help functions

  dispatchEvent(eventName, detail) {
    document.dispatchEvent(new CustomEvent(eventName, { detail }))
  }
}
