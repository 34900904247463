import { getAddress } from 'viem'
import { getTokenPrice } from '@/web3/logic/getTokenPrice.js'
import { Token } from '@uniswap/sdk-core'

export async function getNativePrice(chain) {
  try {
    return await getTokenPriceRep(chain.wrapped_native, chain)
  } catch (error) {
    console.log(chain.recipient_currency.symbol)
    console.error('Error getting native price :', error)
    throw error
  }
}

export async function getTokenPriceRep(tokenIn, chain) {
  if (tokenIn.symbol === chain.recipient_currency.symbol) return 1
  const tokenOut = createTokenInstance(chain.recipient_currency, chain.id)
  tokenIn = createTokenInstance(tokenIn, chain.id)
  const { averages } = await getTokenPrice(tokenIn, tokenOut)
  if (!averages) return 1
  const repPrice = extractSignificantPrice(averages)
  return repPrice
}

export function createTokenInstance(token, chainId) {
  return new Token(
    chainId,
    getAddress(token.contract_address || token.address),
    token.decimals,
    token.symbol,
    token.name
  )
}

export function extractSignificantPrice(averages) {
  if (!averages) {
    console.log(`Error: averages ${averages}`)
    return
  }

  const result = averages.invert
    ? averages.twap.invert().toSignificant()
    : averages.twap.toSignificant()

  return result
}
