import { WalletManager } from '@/web3/logic/wallet_manager.js'
import Dialog from '@stimulus-components/dialog'
import { Wagmi } from '@/web3/config/wagmi'

export default class extends Dialog {
  static targets = ['connectButton', 'disconnectButton', 'message', 'connectorsDropdown']

  connect() {
    super.connect()
    WalletManager.watchAccountChanges()
    WalletManager.reconnect()
    document.addEventListener('accountChanged', (e) => this.updateUI(e.detail.isConnected))
    this.updateUI(WalletManager.isConnected)
    this.renderConnectButtons()
  }

  async connectWallet(event) {
    const connector = WalletManager.getConnector(event.currentTarget.dataset.connectorId)
    if (connector) {
      try {
        await WalletManager.connect(connector)
        this.dialogTarget.close()
      } catch (error) {
        console.error('Error connecting wallet:', error)
        this.messageTarget.textContent = 'Failed to connect wallet. Please try again.'
      }
    }
  }

  async disconnectWallet() {
    await WalletManager.disconnect()
    this.updateUI(false)
  }

  updateUI(isConnected) {
    this.connectButtonTarget.classList.toggle('hidden', isConnected)
    this.disconnectButtonTarget.classList.toggle('hidden', !isConnected)
    this.messageTarget.textContent = isConnected ? WalletManager.addressTrim : ''
  }

  renderConnectButtons() {
    this.connectorsDropdownTarget.innerHTML = WalletManager.connectors
      .map((connector) => this.createConnector(connector))
      .join('')
  }

  createConnector(connector) {
    const icon = connector.icon
      ? connector.icon
      : new URL(`../../images/${connector.type.toLowerCase()}.svg`, import.meta.url).href
    return `
            <div class="flex px-4 py-2 min-h-20 w-full items-stretch">
                <button id="${connector.uid}" class="connect relative flex grow p-0 hover:bg-gray-50 rounded-lg items-stretch" data-action="click->app--wallet#connectWallet" data-connector-id="${connector.id}">
                    <div class="flex grow flex-row items-center gap-x-4 px-4 py-2 w-full">
                        <img src="${icon}" alt="${connector.name}" class="w-[32px] inline">
                        <div class="flex-grow">
                            <p class="text-start font-bold">${connector.name}</p>
                        </div>
                        <span class="text-zinc-600 font-bold">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 stroke-2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>
                        </span>
                    </div>
                </button>
            </div>
        `
  }
}
