import { getAccount, readContract, signTypedData } from '@wagmi/core'
import { Wagmi } from '@/web3/config/wagmi.js'
import { hexToSignature, pad, toHex, domainSeparator } from 'viem'
import { IERC20_2612_ABI } from '@/web3/abis/IERC20_2612_ABI.js'
import { getTokenAllowance } from '@/web3/logic/getTokenAllowance.js'

const readContractProperty = async (address, functionName, args = []) => {
  return await readContract(Wagmi.config, {
    abi: IERC20_2612_ABI,
    address,
    functionName,
    args,
  })
}

const getPermitDomainVersion = async (contract) => {
  const knownDomainVersions = {
    '0x3c499c542cef5e3811e1192ce70d8cc03d5c3359': '2', // USDC on Polygon
    '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48': '2', // USDC on Ethereum
    '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1': '2', // DAI on Arbitrum and Optimism (perhaps other chains too)
  }

  if (knownDomainVersions[contract.address]) {
    return knownDomainVersions[contract.address]
  }

  try {
    return await readContractProperty(contract.address, 'version')
  } catch {
    return '1'
  }
}

export const getPermitDomain = async (contract) => {
  const verifyingContract = contract.address
  const chainId = contract.chainId

  const [version, name, symbol, contractDomainSeparator] = await Promise.all([
    getPermitDomainVersion(contract),
    readContractProperty(contract.address, 'name'),
    readContractProperty(contract.address, 'symbol'),
    readContractProperty(contract.address, 'DOMAIN_SEPARATOR'),
  ])

  const salt = pad(toHex(chainId), { size: 32 })

  const potentialDomains = [
    { name, version, chainId, verifyingContract },
    { name, version, verifyingContract, salt },
    { name: symbol, version, chainId, verifyingContract },
    { name: symbol, version, verifyingContract, salt },
    { name, chainId, verifyingContract },
    { name, verifyingContract, salt },
    { name: symbol, chainId, verifyingContract },
    { name: symbol, verifyingContract, salt },
    { version, chainId, verifyingContract },
    { version, verifyingContract, salt },
    { chainId, verifyingContract },
    { verifyingContract, salt },
    { name, version, chainId, verifyingContract, salt },
    { name: symbol, version, chainId, verifyingContract, salt },
  ]

  const domain = potentialDomains.find(
    (domain) => domainSeparator({ domain }) === contractDomainSeparator
  )

  if (!domain) {
    throw new Error('Could not determine Permit Signature data')
  }

  return domain
}

export async function getSignature(chain, token, intent) {
  const account = getAccount(Wagmi.config)

  const [domain, nonce] = await Promise.all([
    getPermitDomain(token),
    readContractProperty(token.address, 'nonces', [account.address]),
  ])

  const deadline = intent.deadline
  const value = intent.fee_amount + intent.recipient_amount
  const signature = await signTypedData(Wagmi.config, {
    domain: domain,
    types: {
      Permit: [
        { name: 'owner', type: 'address' },
        { name: 'spender', type: 'address' },
        { name: 'value', type: 'uint256' },
        { name: 'nonce', type: 'uint256' },
        { name: 'deadline', type: 'uint256' },
      ],
    },
    primaryType: 'Permit',
    message: {
      owner: account.address,
      spender: chain.contract_address,
      value,
      nonce: nonce.toString(),
      deadline,
    },
  })

  return {
    owner: account.address,
    signature: signature,
  }
}
