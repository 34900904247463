import { Controller } from '@hotwired/stimulus'
import { processTransaction } from '@/web3/services/transactionService.js'
import { waitForTransactionReceipt } from '@wagmi/core'
import { TransferredEventDecoder } from '@/web3/utils/transferredEventDecoder.js'
import { getNetworkFee } from '@/web3/utils/networkFee'
import { RenderCheckout } from '@/web3/ui/checkoutUi'
import dateNow from '@/web3/utils/dateNow'
import { Wagmi } from '@/web3/config/wagmi.js'
import { Charge } from '@/web3/logic/charge.js'

export default class extends Controller {
  static targets = [
    'token',
    'txHash',
    'paymentSuccess',
    'info',
    'paymentResult',
    'paymentDetails',
    'from',
    'network',
    'subtotal',
    'total',
    'fee',
    'date',
    'status',
    'paymentBox',
    'email',
    'payButton',
    'spinner',
    'payButtonTittle',
  ]

  static values = {
    token: Object,
    amount: Number,
    chains: Array,
  }

  connect() {
    document.addEventListener('tokenSelected', this.handleTokenSelected)
    document.addEventListener('chainUpdated', this.handleChainUpdated)
    document.addEventListener('transactionServiceEvent', this.handleTransactionServiceEvent)
  }

  // Event Handlers
  handleTokenSelected = (event) => {
    this.tokenSelected = event.detail
  }

  handleChainUpdated = (event) => {
    const { chain, currency, wrapped_native, recipient_currency } = event.detail
    this.chain = chain
    this.currency = currency
    this.wrapped_native = wrapped_native
    this.recipient_currency = recipient_currency
  }

  handleTransactionServiceEvent = (event) => {
    const { state, token } = event.detail
    switch (state) {
      case 'approve':
        this.payButtonTittleTarget.innerHTML = `Unlocking ${token.symbol}`
        break
      case 'approved':
        this.payButtonTittleTarget.innerHTML = `Proceeding with payment`
        break
      default:
        console.log(`Unknown transaction state: ${state}`)
    }
  }

  // Target Listeners
  selectToken = async (event) => {
    const email = this.emailTarget.value.trim()
    if (!this.tokenSelected || !this.chain || !email) {
      console.log('Error: token, chain, or email not selected')
      return
    }

    this.spinnerTarget.classList.toggle('hidden', false)
    this.toggleButtonState(false)

    try {
      const { hash, chargeId } = await processTransaction(
        this.tokenSelected,
        this.chain,
        this.amountValue
      )
      this.chargeId = chargeId
      await this.renderPending(hash)
      Charge.update(hash, 1, email)

      const receipt = await waitForTransactionReceipt(Wagmi.config, { hash, confirmation: 12 })
      const results = await TransferredEventDecoder(receipt.logs, this.chain)
      Charge.update(hash, 2, email)
      await this.renderSuccess(receipt, results[0])
    } catch (err) {
      this.renderFail(err.message)
      console.log(`Error ${err}:`)
    }
  }

  checkEmail = () => {
    this.toggleButtonState(this.emailTarget.value.trim() !== '')
  }

  // UI Methods
  toggleInfo = () => {
    this.infoTarget.classList.toggle('hidden')
    this.paymentResultTarget.classList.toggle('hidden')
  }

  toggleButtonState = (enable) => {
    this.payButtonTarget.disabled = !enable
    this.payButtonTarget.classList.toggle('opacity-50', !enable)
  }

  renderPending = async (hash) => {
    this.paymentBoxTarget.classList.add('hidden')
    this.paymentSuccessTarget.classList.remove('hidden')
    this.fromTarget.innerHTML = RenderCheckout.paymentInfo('From', this.tokenSelected.symbol)
    this.subtotalTarget.innerHTML = RenderCheckout.paymentInfo('Subtotal', `$${this.amountValue}`)
    this.dateTarget.innerHTML = RenderCheckout.paymentInfo('Date', dateNow())
    this.statusTarget.innerHTML = RenderCheckout.paymentInfo(
      'Status',
      RenderCheckout.status('pending')
    )
    this.txHashTarget.href = `${this.chain.block_explorer}/tx/${hash}`
  }

  renderSuccess = async (transaction, result) => {
    const { gasUsed, effectiveGasPrice } = transaction

    try {
      const { feeNative, feeRep } = await getNetworkFee(this.chain, gasUsed)
      const symbol = this.chain.recipient_currency.symbol
      this.feeTarget.innerHTML = RenderCheckout.paymentInfo(
        'Network fee',
        `${symbol} ${feeRep.toFixed(3)} • ${feeNative.toFixed(5)} ${this.chain.currency}`
      )
    } catch (err) {
      console.error('Error calculating network fee:', err)
    }

    this.subtotalTarget.innerHTML = RenderCheckout.paymentInfo(
      'Subtotal',
      `$${this.amountValue} - ${Number(result.spentAmount).toFixed(5)} ${result.spentSymbol}`
    )
    this.statusTarget.innerHTML = RenderCheckout.paymentInfo(
      'Status',
      RenderCheckout.status('paid')
    )
  }

  async renderFail(errorMessage) {
    this.toggleButtonState(true)
    this.spinnerTarget.classList.toggle('hidden', true)
    this.payButtonTittleTarget.innerHTML = `Pay`
    this.statusTarget.innerHTML = RenderCheckout.paymentInfo(
      'Status',
      RenderCheckout.status('failed')
    )
  }
}
