import { post, put } from '@rails/request.js'

export class Charge {
  static current = {}

  constructor(account, chain) {
    Charge.current.address = account.address
    Charge.current.chain = chain
  }

  static async getData(email = null) {
    const { address, chain } = Charge.current

    const response = await post(window.location.href + '/charge', {
      body: JSON.stringify({ charge: { payee: address, chain_id: chain.id } }),
      headers: { 'Content-Type': 'application/json' },
    })

    if (response.ok) {
      Charge.current.data = await response.json
    }
    return Charge.current.data
  }

  static async update(hash, status, email = null) {
    const chargeId = Charge.current.data.web3_data.transfer_intent.metadata.public_id
    const body = {
      charge: { tx: hash.toString(), chain_id: Charge.current.chain.id, status: status },
    }
    if (email) body.charge.email = email

    try {
      const response = await put(window.location.href + `/charge/`, {
        body: JSON.stringify(body),
        headers: { 'Content-Type': 'application/json' },
        responseKind: 'turbo-stream',
      })

      if (!response.ok) {
        console.error('Failed to update charge status:', response)
      }
    } catch (error) {
      console.error('Error updating charge status:', error)
    }
  }

  static async payGasLess(signature, email = null) {
    const chargeId = Charge.current.data.web3_data.transfer_intent.metadata.public_id
    const body = { charge: { owner: signature.owner, signature: signature.signature } }
    if (email) body.charge.email = email

    try {
      const response = await post(window.location.href + `/gas_less_charge/`, {
        body: JSON.stringify(body),
        headers: { 'Content-Type': 'application/json' },
        responseKind: 'turbo-stream',
      })

      const tx = Charge.extractTransactionHash(await response.text)

      if (!response.ok) {
        console.error('Failed to update charge status:', response)
      }
      return tx
    } catch (error) {
      console.error('Error updating charge status:', error)
    }
  }

  static extractTransactionHash(htmlString) {
    const regex = /data-hash="(0x[0-9a-fA-F]+)"/
    const match = htmlString.match(regex)
    return match ? match[1] : null
  }
}
